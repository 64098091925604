@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .nav-icon {
    @apply text-cgc-green hover:text-cgc-green-40 h-[30px] w-[30px];
  }

  a {
    @apply text-cgc-black hover:text-cgc-grey dark:text-cgc-white underline hover:no-underline;
  }

  a.secondary {
    @apply text-cgc-blue hover:text-cgc-blue-80;
  }

  a.tertiary {
    @apply text-cgc-green hover:text-cgc-green-80;
  }

  a.plain {
    @apply no-underline;
  }

  .text-3xl {
    font-size: 2.5rem;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .graphic-background {
    @apply after:fixed after:inset-0 after:-z-10 after:bg-[url(/assets/customer-portal-bg.png)] after:bg-cover after:bg-right-top after:bg-no-repeat;
  }
}

.icon-background {
  @apply relative after:absolute after:inset-0 after:-z-10 after:bg-[url(/assets/customer-portal-bg.png)] after:bg-contain after:bg-center after:bg-repeat-y;
}

.member-zone {
  min-height: 100vh;
  background: url("/yellow-box-spill-flipped.jpg") no-repeat right bottom fixed;
  background-size: cover;
  padding-top: 0 !important;
}

/* OneTrust */
#onetrust-banner-sdk #onetrust-policy-text,
#onetrust-banner-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk .cookie-setting-link {
  font-family: var(--font-mohr) !important;
}

input[type="date"] {
  position: relative;
  padding: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  font-family: 'FontAwesome';
  content: '\f073';
  width: 15px;
  height: 20px;
  position: absolute;
  top: 12px;
  right: 12px;
  color: #000000;
}